import React from "react"
import Page from "../components/page/Page"
import { graphql } from "gatsby"
import "./ensenanza.css"

export default props => {
  const librosEdges = props.data.allContentfulEnsenanza.edges
  return (
    <Page class="teaching" slug="ensenanza" title="Enseñanza">
      <div className="list">
        <header className="list-header">
          <h1 className="title">Enseñanza</h1>
          <div className="subtitle">
            Cursos, seminarios y talleres en universidades peruanas y
            extranjeras
          </div>
        </header>
        <main className="list-main">
          {librosEdges.map((edge, i) => {
            return (
              <div className="item" key={i}>
                <h2 className="item-title">{edge.node.titulo}</h2>
                <div className="item-school-year">
                  {edge.node.facultad}, {edge.node.anho}
                </div>
                <div
                  className="item-description"
                  dangerouslySetInnerHTML={{
                    __html: edge.node.descripcion.childMarkdownRemark.html,
                  }}
                />
                <a
                  className="item-download"
                  href={edge.node.pdf ? edge.node.pdf.file.url : null}
                >
                  <i className="material-icons">get_app</i>
                  <span>descargar</span>
                </a>
              </div>
            )
          })}
        </main>
      </div>
    </Page>
  )
}

export const ensenanzaQuery = graphql`
  query {
    allContentfulEnsenanza(sort: { fields: anho, order: DESC }) {
      edges {
        node {
          titulo
          anho
          facultad
          descripcion {
            childMarkdownRemark {
              html
            }
          }
          pdf {
            file {
              url
            }
          }
        }
      }
    }
  }
`
